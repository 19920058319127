import { atom } from 'jotai';


interface LoginModalState {
    open: boolean;
    navLink: string;
}

export const selectSportsAtom = atom<string[]>([]);
export const billingCycleAtom = atom<'monthly' | 'annually'>('monthly');
export const loginModalAtom = atom<LoginModalState>({
    open: false,
    navLink: "",
})
export const liveAtom = atom<boolean>(false);
export const liveBtn = atom<boolean>(true);

export const hoursAtom = atom<number>(0);
export const minutesAtom = atom<number>(0);
export const secondsAtom = atom<number>(0);