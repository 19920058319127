import LazyLoadingPage from "@components/LazyLoadingPage.jsx";
import { SidebarProvider, SidebarTrigger } from "@ui/sidebar";
import { useInitializeApp } from "@hooks/useInitializeApp";
import { useIsAuthenticated } from "@hooks/selectorHooks";
import { useSchoolInfo } from "@hooks/useQueryHooks";
import { Routes, Route } from "react-router";
import HomePage from "@pages/HomePage.jsx";
import { lazy, Suspense } from "react";


const ErrorPage = lazy(() => import("@pages/404page/ErrorPage"))
const DashBoardPage = lazy(() => import("@pages/dashboard/DashboardPage"));
const ScoringPage = lazy(() => import("@pages/scoring/ScoringPage.jsx"));
const RugbyScoringPage = lazy(() => import("@pages/scoring/Rugby/RugbyScoringPage.jsx"));
const TennisScoringPage = lazy(() => import("@pages/scoring/Tennis/TennisScoringPage.jsx"));
const NetballScoringPage = lazy(() => import("@pages/scoring/Netball/NetballScoringPage.jsx"));
const CricketScoringPage = lazy(() => import("@pages/scoring/Cricket/CricketScoringPage.jsx"));
const HockeyScoringPage = lazy(() => import("@pages/scoring/Hockey/HockeyScoringPage.jsx"));

const LoginPage = lazy(() => import("@pages/login/LoginPage.jsx"));
const RegisterPage = lazy(() => import("@pages/register/RegisterPage.jsx"));
const ActivePage = lazy(() => import("@pages/activate/[id]/[token]/ActivePage.jsx"));
const Activate = lazy(() => import("@pages/activate/Activate.jsx"));
const ResetPasswordConfirm = lazy(() => import("@pages/password/reset/confirm/[uid]/[token]/ResetPasswordConfirm.jsx"));

const About = lazy(() => import("@pages/about/About.jsx"));
const ContactUs = lazy(() => import("@pages/contactUs/Contact.jsx"));
const Terms = lazy(() => import("@pages/termsOfUse/Terms.jsx"));
const Policy = lazy(() => import("@pages/privacyPolicy/Policy.jsx"));
const Pricing = lazy(() => import("@pages/pricing/PricingPage"));
const Features = lazy(() => import("@pages/features/Features.tsx"));

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { data } = useSchoolInfo();

  useInitializeApp()

  const school_type = data?.school_type;
  const school_name = data?.school_name;
  const school_instance = data?.id;
  const schoolLogo = data?.school_logo;
  const sportSelected = {
    'cricket': data?.cricket,
    'rugby': data?.rugby,
    'netball': data?.netball,
    'tennis': data?.tennis,
    'hockey': data?.hockey,
  }

  return (
    <Routes>
      <Route path="/" element={
        <Suspense fallback={<LazyLoadingPage />}>
          <HomePage />
        </Suspense>
      } />
      <Route path="/scoring">
        <Route
          index
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <ScoringPage sportSelected={sportSelected} />
            </Suspense>
          }
        />
        <Route
          path="rugby"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <RugbyScoringPage
                school_type={school_type}
                school_name={school_name}
                school_instance={school_instance}
              />
            </Suspense>
          }
        />
        <Route
          path="tennis"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <TennisScoringPage
                school_type={school_type}
                school_name={school_name}
                school_instance={school_instance}
              />
            </Suspense>
          }
        />
        <Route
          path="netball"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <NetballScoringPage
                school_type={school_type}
                school_name={school_name}
                school_instance={school_instance}
                isAuthenticated={isAuthenticated}
              />
            </Suspense>
          }
        />
        <Route
          path="cricket"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <CricketScoringPage
                school_type={school_type}
                school_name={school_name}
                school_instance={school_instance}
                isAuthenticated={isAuthenticated}
              />
            </Suspense>
          }
        />
        <Route
          path="hockey"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <HockeyScoringPage
                school_type={school_type}
                school_name={school_name}
                school_instance={school_instance}
                schoolLogo={schoolLogo}
                isAuthenticated={isAuthenticated}
              />
            </Suspense>
          }
        />
      </Route>
      <Route
        exact
        path="/login"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/password/reset/confirm/:uid/:token"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <ResetPasswordConfirm />
          </Suspense>
        }
      />
      <Route
        path="/activate/:uid/:token"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <ActivePage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/register"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <RegisterPage />
          </Suspense>
        }
      />
      <Route
        exact
        path="/activate"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <Activate />
          </Suspense>
        }
      />
      <Route
        exact
        path="/about-us"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <About />
          </Suspense>
        }
      />
      <Route
        exact
        path="/contact-us"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        exact
        path="/terms-of-use"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <Terms />
          </Suspense>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <Policy />
          </Suspense>
        }
      />
      <Route 
        exact
        path="/pricing"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <Pricing />
          </Suspense>
        }
      />
      <Route 
        exact
        path="/features"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <Features />
          </Suspense>
        }
      />
      <Route
        exact
        path="/dashboard/*"
        element={
          <Suspense fallback={<LazyLoadingPage />}>
            <SidebarProvider>
              <SidebarTrigger />
              <DashBoardPage />
            </SidebarProvider>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={<Suspense fallback={<LazyLoadingPage />}><ErrorPage /></Suspense>}
      />
    </Routes>
  );
}

export default App;
