import axios from 'axios';
import Cookie from 'js-cookie';

export async function otherSports(isAuthenticated: boolean, id: string) {
    console.log("Function", id, isAuthenticated)
    if (!isAuthenticated) {
        return null; // or you can return a specific type if required, e.g., {} or an error message
    }

    const accessToken = Cookie.get('access');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${accessToken}`,
            'Accept': 'application/json',
        },
    }

        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/stats/player-sports/?athlete_id=${id}`, config);

        return response.data;
}