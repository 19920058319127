import SportIconsMobile from "@images/HomePage/SportIconsMobile.webp";
import SportIcons from "@images/HomePage/SportIcons.webp";
import LazyLoadingPage from "@components/LazyLoadingPage";
import useHandleClick from "@hooks/useHandleClick";
import TypingAnimation from "@ui/typing-animation";
import LazySection from "@components/LazySection";
import styles from "@styles/homepage.module.css";
import { sportTypes } from "@utils/schoolTypes";
import { loginModalAtom } from "@utils/atoms";
import { Card, CardContent } from "@ui/card";
import { useNavigate } from "react-router";
import NavBar from "@components/NavBar";
import { lazy, Suspense } from "react";
import BlurFade from "@ui/blur-fade";
import { useAtomValue } from "jotai";
import PropTypes from 'prop-types';


const FooterPage = lazy(() => import("@components/FooterPage"));
const PricingSection = lazy(() => import("@components/PricingSection"));
const LoginModal = lazy(() => import("@components/LoginModal"));
const UsingScoringWiz = lazy(() => import("@components/UsingScoringWiz"));
const WhatIsScoringWiz = lazy(() => import("@components/WhatIsScoringWiz"))

export default function HomePage() {
  const navigate = useNavigate();
  const loginModal = useAtomValue(loginModalAtom);
  const handleClick = useHandleClick();

  function handleNavigate(e) {
    if (e.currentTarget.id === 'live') {
      window.location.href = `${import.meta.env.VITE_FRONTEND_SUB_URL}`;
    } else if (e.currentTarget.id === 'register') {
      navigate('/register');
    }
  }

  return (
    <>
      <title>ScoringWiz</title>
      <meta name="description" content="The all in one sport scoring app" />
      <meta name='keywords' content="sport, scoring, app, cricket scoring app, rugby scoring app, netball scoring app, tennis scoring app, hockey scoring app, ScoringWiz" />
      <NavBar />
      <main className="md:min-h-screen">
        <section className={styles.main}>
          <div className={styles.main__header}>
            <div className={styles.main__headerContainer}>
              <div className='text-center'>
                <TypingAnimation
                  className="text-primary-dark mt-4 md:mt-24 text-3xl md:text-6xl font-bold font-fuzzy-bubbles tracking-tighter h-[72px] md:h-[120px]"
                >
                  Where the love of scoring and stats meet.
                </TypingAnimation>
                <TypingAnimation className="text-xl font-normal mt-12 md:mt-0">
                  The only scoring app you will ever need
                </TypingAnimation>
              </div>
              <BlurFade delay={1.75}>
                <div className="z-50 mb-10 mt-8 md:mb-0 flex gap-8 justify-center flex-wrap" >
                  {sportTypes.map((sport, index) => (
                    <Card id={sport.name.toLowerCase()} key={index} onClick={(e) => handleClick(e)} className="rounded flex flex-col size-20 content-center justify-center p-1 hover:shadow-lg transition-shadow pt-6 hover:scale-125 hover:cursor-pointer">
                      <CardContent className="items-center flex flex-col">
                        <sport.icon className="text-primary-dark " />
                        <h3 className="text-md mt-0.5">{sport.name}</h3>
                      </CardContent>
                    </Card>
                  ))}
                </div>
                <div className={styles.main__callToAction}>
                  <button className={`${styles.main__registerBtn} ${styles.btn}`} id='register' onClick={(e) => handleNavigate(e)}>Register</button>
                  <button className={`${styles.main__liveBtn} ${styles.btn}`} id='live' onClick={(e) => handleNavigate(e)}>Live Scores</button>
                </div>
              </BlurFade>
            </div>
            <div className={styles.sportIcons}>
              <picture>
                <source srcSet={SportIconsMobile} media="(max-width: 750px)" className={styles.desktopHero} alt='sports icons'  />
                <img src={SportIcons} alt='sports icons' loading="lazy" />
              </picture>
              <div className={styles.scrollSVG}></div>
            </div>
          </div>
        </section>
        {/* What is ScoringWiz Section */}
        <Suspense fallback={<LazyLoadingPage />}>
          <WhatIsScoringWiz />
        </Suspense>

        <LazySection>
          {/* The Using ScoringWiz Section */}
          <Suspense fallback={<LazyLoadingPage />}>
            <UsingScoringWiz />
          </Suspense>
        </LazySection>
        
        <LazySection>
          {/* Register Section */}
          <Suspense fallback={<LazyLoadingPage />}>
            <PricingSection />
          </Suspense>
        </LazySection>
      </main>
      <FooterPage />
      <Suspense fallback={<LazyLoadingPage />}>
        {loginModal && <LoginModal />}
      </Suspense>
    </>
  );
}

HomePage.propTypes = {
  isAuthenticated: PropTypes.bool,
  data: PropTypes.object,
};